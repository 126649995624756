import React, {useContext, useEffect, useRef, useState} from "react";

import parseHTML from "html-react-parser";

import {Container, Row, Col} from "react-bootstrap";


import plkaLogo from "./_Images/plka_logo.png";
import plkaBanner from "./_Images/plka_banner.png";
import GlobalDataContext from "@Contexts/Globals";
import scrollToDomElement from "@Utilities/Scrolling";
import GLOBAL_REST_API_DATA from "@Globals/API";
import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import {NavLink} from "react-router-dom";
import noImage from "@Images/no-image.png";

const {URLS: {PAGES: {newsPage: {articlesListPage: NEWS_PAGE_URL}}}} = GLOBAL_REST_API_DATA;


export const HomePageComponent = () => {
	
	
	const REST_API_URL = useContext(GlobalDataContext)?.restApiData?.mainRestApiURL;
	
	
	const homePageRef = useRef(null);
	
	
	const [homePageData, setHomePageData] = useState(null);
	
	const [ARTICLES_LIST, SET_ARTICLES_LIST] = useState(null);
	
	const GET_ARTICLES_LIST = async () => {
		try {
			const response = await fetch(NEWS_PAGE_URL);
			const content = await response.json();
			SET_ARTICLES_LIST(content?.results);
		} catch (e) {
			console.log(e);
			SET_ARTICLES_LIST({error: true});
		}
	};
	
	useEffect(() => {
		if (!NEWS_PAGE_URL) return null;
		GET_ARTICLES_LIST();
	}, [NEWS_PAGE_URL]);
	
	
	useEffect(() => {
		if (!REST_API_URL) return null;
		// fetch(`${REST_API_URL}/pages/news/${articleSLug}`)
		// 	.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
		// 	.then(resource => setArticlePageData(resource))
		// 	.catch(error => {return {error: true, code: 500, message: error}})
		// ;
		scrollToDomElement(homePageRef.current,window.innerWidth < 992 ? 250 : 150);
		return () => {
			setHomePageData(null);
		};
		
	}, [REST_API_URL]);
	
	
	const MAIN_ARTICLE = ARTICLES_LIST ? ARTICLES_LIST[0] : null;
	const ARTICLES = ARTICLES_LIST ? ARTICLES_LIST.slice(1, 5) : null;
	
	console.log(MAIN_ARTICLE);
	
	
	return (
		<Container fluid={true} className={`home-page-component`} ref={homePageRef}>
		
			<Container fluid={true} className={"home-page-news"}>
				<Container className={"home-page-news-container"}>
					<Row className={"home-page-news-content"}>
					
						<Col xs={12} className={"home-page-main-article"}>
							{!MAIN_ARTICLE && <MainContentLoaderComponent />}
							{!!MAIN_ARTICLE &&
							<Row className={"home-page-main-article-content"}>
								<Col xs={12} lg={4} className={"main-article-content"}>
									<Row className={"main-article-content-title"}>
										<Col xs={12} className={"article-title"}>
											<NavLink to={`/aktualnosci/${MAIN_ARTICLE?.blog_page_slug}`} title={MAIN_ARTICLE?.blog_page_title}>
												<span>{MAIN_ARTICLE?.blog_page_title}</span>
											</NavLink>
										</Col>
									</Row>
									<Row className={"main-article-content-short-description"}>
										<Col xs={12} className={"article-short-description"}>
											<NavLink to={`/aktualnosci/${MAIN_ARTICLE?.blog_page_slug}`} title={MAIN_ARTICLE?.blog_page_title}>
												{parseHTML(MAIN_ARTICLE?.blog_page_short_content || "")}
											</NavLink>
										</Col>
									</Row>
								</Col>
								<Col xs={12} lg={8} className={"main-article-image"}>
									<Row className={"main-article-picture"} as={"picture"}>
										<img src={MAIN_ARTICLE?.blog_page_image || noImage} />
									</Row>
								</Col>
							</Row>
							}
						</Col>
						
						{!!ARTICLES && ARTICLES?.length > 1 &&
						<Col xs={12} lg={{span: 8, offset: 4}}>
							<Row className={"home-page-articles-list"}>
							{ARTICLES.map((article, i) =>
								<Col key={i} xs={12} lg={3} className={"articles-list-article"}>
									<NavLink to={`/aktualnosci/${article?.blog_page_slug}`} title={article?.blog_page_title}>
										<span>{article?.blog_page_title}</span>
									</NavLink>
								</Col>
							)}
							</Row>
						</Col>
						}
						
					</Row>
				</Container>
			</Container>
		
		</Container>
	);
	
};


export default HomePageComponent;
