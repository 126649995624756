import React from "react";


import {Alert, Col} from "react-bootstrap";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";


export const E404PageComponent = () => {
	
	
	return (
		<MainSectionContainerComponent as={`main`} cssClasses={`e404-page-component error-page-component`}>
		
			<Col as={Alert} xs={12} variant={"danger"}>
				<span>{`Nie znaleziono strony`}</span>
			</Col>
		
		</MainSectionContainerComponent>
	);
	
};


export default E404PageComponent;
