import React from "react";


import {Col} from "react-bootstrap";


export const MainSectionImageComponent = ({
	imageURL, imageTitle = "",
	xs = 12, sm = 12, lg = 12, xl = 12,
	as = "picture", cssClasses = "",
	props
}) => {
	
	
	if (!imageURL) return null;
	
	
	cssClasses = "main-section-image" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col as={as} xs={xs} sm={sm} lg={lg} xl={xl} className={cssClasses} {...props}>
			<img src={imageURL} alt={imageTitle} />
		</Col>
	);
	
	
};


export default MainSectionImageComponent;
