// const API_ROOT_URL = "http://localhost:8080";
const API_ROOT_URL = "https://plka-project-admin.webprosdev.usermd.net";
const REST_API_ROOT_URL = `${API_ROOT_URL}/api/v1`;


export const GLOBAL_REST_API_DATA = {
	URLS: {
		apiRootURL: REST_API_ROOT_URL,
		pingApiURL: `${API_ROOT_URL}/app/security/ping/`,
		getCsrfTokenURL: `${API_ROOT_URL}/app/security/csrf/`,
		EMAILS: {
			sendEmailURL: `${API_ROOT_URL}/app/emails/`
		},
		SOCIALS: {
			socialsLinks: `${REST_API_ROOT_URL}/socials/`
		},
		PARTNERS: {
			partnersList: `${REST_API_ROOT_URL}/partners/`
		},
		PAGES: {
			contactPage: `${REST_API_ROOT_URL}/pages/contact/`,
			newsPage: {
				articlesListPage: `${REST_API_ROOT_URL}/pages/news/`,
				singleArticlePage: `${REST_API_ROOT_URL}/pages/news`
			},
			joinUsPage: `${REST_API_ROOT_URL}/pages/join-us/`
		},
	}
};


export default GLOBAL_REST_API_DATA;
