import React from 'react';
import ReactDOM from 'react-dom';


import reportWebVitals from './reportWebVitals';


import GlobalDataContext, {GLOBAL_DATA} from "@Contexts/Globals";

import {Provider as ApplicationStoreProvider} from "react-redux";
import ApplicationStore from "@Store";


import Application from "./Application";


ReactDOM.render(
    <React.StrictMode>
        <GlobalDataContext.Provider value={GLOBAL_DATA}>
            <ApplicationStoreProvider store={ApplicationStore}>
                <Application />
            </ApplicationStoreProvider>
        </GlobalDataContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


reportWebVitals();
