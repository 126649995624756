import React from "react";


import {Col} from "react-bootstrap";


export const SocialsSocialComponent = ({socialData}) => {
	
	
	if (!socialData) return null;
	
	
	const {
		// social_id: socialID,
		social_title: socialTitle,
		social_url: socialUrl,
		social_icon: socialIcon,
		social_active_status: socialActiveStatus,
	} = socialData;
	
	
	if (!socialActiveStatus || !socialUrl) return null;
	
	
	return (
		<Col as={`li`} className={`header-socials-element`}>
			<a href={socialUrl} title={socialTitle} target={`_blank`} rel={`noreferrer noopener`}>
				<img src={socialIcon} alt={socialTitle} />
			</a>
		</Col>
	);
	
};


export default SocialsSocialComponent;
