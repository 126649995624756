import React, {useEffect, useRef, useState} from "react";

import GLOBAL_REST_API_DATA from "@Globals/API";


import {Redirect} from "react-router-dom";


import {Col} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";
import MainSectionTextComponent from "@Elements/Sections/Content/TextContent";
import MainSectionImageComponent from "@Elements/Sections/Content/MainImage";


const {URLS: {PAGES: {newsPage: {singleArticlePage: ARTICLE_PAGE_URL}}}} = GLOBAL_REST_API_DATA;


export const ArticlePageComponent = ({location, match}) => {
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const [ARTICLE_PAGE_DATA, SET_ARTICLE_PAGE_DATA] = useState(null);
	
	const GET_ARTICLE_PAGE_DATA = async () => {
		const ARTICLE_SLUG = match?.params?.articleSlug;
		if (!ARTICLE_SLUG) return {error: true};
		try {
			const response = await fetch(`${ARTICLE_PAGE_URL}/${ARTICLE_SLUG}/`);
			const content = await response.json();
			SET_ARTICLE_PAGE_DATA(content);
		} catch (e) {
			console.log(e);
			SET_ARTICLE_PAGE_DATA({error: true});
		}
	};
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current)
		if (!ARTICLE_PAGE_URL) return null;
		GET_ARTICLE_PAGE_DATA();
	}, [ARTICLE_PAGE_URL]);
	
	
	if (!ARTICLE_PAGE_URL || ARTICLE_PAGE_DATA?.error) return <Redirect to={`/404`} />;
	
	
	if (!ARTICLE_PAGE_DATA) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	
	const {
		// blog_page_id: blogPageID,
		blog_page_title: blogPageTitle,
		blog_page_content: blogPageContent,
		blog_page_image: blogPageImage,
		blog_page_author: blogPageAuthor,
		blog_page_published_status: blogPagePublishedStatus,
		blog_page_create_date: blogPageCreatDate
	} = ARTICLE_PAGE_DATA;
	
	
	if (!blogPagePublishedStatus) return <Redirect to={`/404`} />;
	
	
	return (
		<MainSectionContainerComponent as={`main`} cssClasses={`article-page-component`} forwardRef={CONTAINER_REF}>
			
			<MainSectionHeaderComponent headerText={blogPageTitle} />
			
			<MainSectionContentComponent>
				
				<MainSectionImageComponent
					imageURL={blogPageImage}
					imageTitle={blogPageTitle}
				/>
				
				<MainSectionTextComponent textContent={blogPageContent} />
				
				<Col xs={12} className={`article-footer`}>
					<span className={`article-author`}>{blogPageAuthor}</span>
					<span className={`article-date`}>{(new Date(blogPageCreatDate)).toLocaleDateString()}</span>
				</Col>
				
			</MainSectionContentComponent>
		
		</MainSectionContainerComponent>
	);
	
};


export default ArticlePageComponent;
