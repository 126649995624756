import React, {useEffect, useRef} from "react";


import scrollToDomElement from "@Utilities/Scrolling";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";

import ArticlesListComponent from "@UIPages/TextPages/Articles/List";


export const ArticlesPageComponent = () => {
	
	
	const CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, window.innerWidth < 992 ? 150 : 100);
	}, []);
	
	
	return (
		<MainSectionContainerComponent as={`main`} cssClasses={`articles-page-component`} forwardRef={CONTAINER_REF}>
		
			<MainSectionHeaderComponent headerText={"Aktualności"} />
		
			<MainSectionContentComponent>
				<ArticlesListComponent />
			</MainSectionContentComponent>
			
		</MainSectionContainerComponent>
	);
	
};


export default ArticlesPageComponent;
