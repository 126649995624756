import React, {useState, useEffect} from "react";

import GLOBAL_REST_API_DATA from "@Globals/API";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";

import SponsorsSponsorComponent from "@UIParts/Sponsors/Sponsor";



const {URLS: {PARTNERS: {partnersList: PARTNERS_LIST_URL}}} = GLOBAL_REST_API_DATA;


export const ApplicationSponsorsComponent = () => {
	
	
	const [SPONSORS_LIST, SET_SPONSORS_LIST] = useState(null);
	
	const GET_SPONSORS_LIST = async () => {
		try {
			const response = await fetch(PARTNERS_LIST_URL);
			const content = await response.json();
			SET_SPONSORS_LIST(content);
		} catch (e) {
			console.log(e);
			SET_SPONSORS_LIST({error: true});
		}
	};
	
	useEffect(() => {
		if (!PARTNERS_LIST_URL) return null;
		GET_SPONSORS_LIST();
	}, [PARTNERS_LIST_URL]);
	
	
	if (!SPONSORS_LIST) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={`Sponsorzy`} />;
	
	
	if (SPONSORS_LIST?.error || SPONSORS_LIST?.length === 0) return null;
	
	
	const carouselSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 1,
		initialSlide: 0,
		autoplay: true,
		autoplaySpeed: 4000,
		pauseOnHover: true,
		// adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 6
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};
	
	
	const sponsorsListComponent = SPONSORS_LIST.map((sponsor, i) =>
		<SponsorsSponsorComponent key={i} sponsorData={sponsor} />
	);
	
	
	return (
		<MainSectionContainerComponent cssClasses={`application-sponsors-component`}>
			
			<Slider {...carouselSettings} className={"sponsors-carousel-component"}>
				{sponsorsListComponent}
			</Slider>
		
		</MainSectionContainerComponent>
	);
	
};


export default ApplicationSponsorsComponent;
