import React, {useState, useEffect, useContext, useRef} from "react";

import GlobalDataContext from "@Contexts/Globals";


import {Redirect} from "react-router-dom";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";
import MainSectionTextComponent from "@Elements/Sections/Content/TextContent";


export const TextPageComponent = ({pageSLug = null}) => {
	
	
	const REST_API_URL = useContext(GlobalDataContext)?.restApiData?.mainRestApiURL;

	
	const textPageRef = useRef(null);
	
	
	const [textPageData, setTextPageData] = useState(null);
	
	
	// useEffect(() => {
	//
	// 	if (!pageSLug || !REST_API_URL) return null;
	//
	// 	fetch(`${REST_API_URL}/pages/${pageSLug}`)
	// 		.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
	// 		.then(resource => setTextPageData(resource[0]))
	// 		.catch(error => {return {error: true, code: 500, message: error}})
	// 	;
	//
	// 	scrollToDomElement(textPageRef.current);
	//
	// 	return () => {
	// 		setTextPageData(null);
	// 	};
	//
	// }, [pageSLug, REST_API_URL]);
	
	
	if (!pageSLug || !REST_API_URL) {
		return <Redirect to={`/404`} />;
	}
	
	
	if (!textPageData) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	if (textPageData.error) return null;
	
	const pagePrefix = pageSLug.replaceAll("-", "_");
	
	const {
		[pagePrefix + "_page_title"]: pageTitle, [pagePrefix + "_page_content"]: pageContent
	} = textPageData;

	
	return (
		<MainSectionContainerComponent as={`main`} cssClasses={`text-page-component`} forwardRef={textPageRef}>
			
			<MainSectionHeaderComponent headerText={pageTitle} />
			
			<MainSectionContentComponent>
				<MainSectionTextComponent textContent={pageContent} />
			</MainSectionContentComponent>
			
		</MainSectionContainerComponent>
	);
	
};


export default TextPageComponent;
