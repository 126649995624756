import React, {useContext, useEffect, useRef, useState} from "react";



import {GoogleMap, useJsApiLoader, Marker, InfoWindow} from '@react-google-maps/api';

import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";
import GLOBAL_REST_API_DATA from "@Globals/API";
import {Col, Row} from "react-bootstrap";

import mapStyles from "./map.json";
import basketballIcon from "@Assets/Icons/basketball_s.png";
const {URLS: {apiRootURL}} = GLOBAL_REST_API_DATA;





export const LeaguesPageComponent = ({}) => {
	
	
	const REST_API_URL = apiRootURL;
	
	
	const leaguesPageRef = useRef(null);
	useEffect(() => scrollToDomElement(leaguesPageRef.current), []);
	
	
	const [leaguesList, setLeaguesList] = useState(null);
	
	useEffect(() => {
		if (!REST_API_URL) return null;
		fetch(`${REST_API_URL}/leagues/list/`)
			.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
			.then(resource => setLeaguesList(resource))
			.catch(error => {return {error: true, code: 500, message: error}})
		;
		return () => {
			setLeaguesList(null);
		};
	}, [REST_API_URL]);
	
	
	const {isLoaded: isMapLoaded} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyDIH0ikRFe6yGyi68nLEumr0evrbg5fjTM"
	});
	
	console.log(isMapLoaded)
	
	const [googleMapComponent, setGoogleMapComponent] = React.useState(null);
	
	useEffect(() => {
		setGoogleMapComponent(googleMapComponentRef);
		return () => {
			setGoogleMapComponent(null);
		};
	}, [isMapLoaded]);
	
	const googleMapComponentRef = useRef(null);
	
	
	if (!leaguesList) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={`Ligi`} />;
	
	
	const LEAGUES = leaguesList.map((localization, i) => {
		const {
			league_city, league_latitude, league_longitude, league_name
		} = localization;
		return (
			<Marker
				key={league_name}
				position={{lat: league_latitude, lng: league_longitude}}
				icon={basketballIcon}
				title={league_name}
			/>
		)
	});
	
	
	return (
		<MainSectionContainerComponent forwardRef={leaguesPageRef} as={`main`} cssClasses={`leagues-page-component`}>
			
			<MainSectionHeaderComponent headerText={"Ligi"} />
			
			<MainSectionContentComponent>
				
				<Row className="map-section-map" style={{margin: 0, width: "100%"}}>
					<Col xs={12} lg={3} className={"map-leagues-list"}>
						<Row className={"map-leagues-list-content"}>
						{leaguesList.map((league, i) =>
							<Col key={i} xs={12} className={"map-leagues-league"}>
								<span>{league.league_name}</span>
							</Col>
						)}
						</Row>
					</Col>
					<Col xs={12} lg={9} style={{minHeight: window.innerWidth > 992 ? 900 : window.innerWidth - 30}}>
						{isMapLoaded &&
						<GoogleMap
							ref={googleMapComponentRef}
							mapContainerStyle={{
								width: "100%",
								height: "100%",
								border: "2px solid #CED4DA"
							}}
							center={{lat: 52.00, lng: 19.21}}
							zoom={window.innerWidth > 992 ? 7 : 5.2}
							options={{styles: mapStyles}}
							fullscreenControl={false}
						>
							{LEAGUES}
						</GoogleMap>
						}
						{!isMapLoaded && <MainContentLoaderComponent />}
					</Col>
				</Row>
				
			</MainSectionContentComponent>
		
		</MainSectionContainerComponent>
	);
	
};


export default LeaguesPageComponent;
