import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";


import isEmail from "validator/lib/isEmail";


import FormComponent from "@Elements/Sections/Forms/Containers/Form";
import FormRowComponent from "@Elements/Sections/Forms/Containers/Row";
import FormTextFieldComponent from "@Elements/Sections/Forms/Fields/Text";
import FormEmailFieldComponent from "@Elements/Sections/Forms/Fields/Email";
import FormTextAreaFieldComponent from "@Elements/Sections/Forms/Fields/TextArea";
import FormSubmitButtonComponent from "@Elements/Sections/Forms/Buttons/Submit";



export const ContactPageFormComponent = ({sendMailRequestHandler = null}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const [formSubjectValue, setFormSubjectValue] = useState("");
	const [formNameValue, setFormNameValue] = useState("");
	const [formEmailValue, setFormEmailValue] = useState("");
	const [formContentValue, setFormContentValue] = useState("");
	
	const [formErrors, setFormErrors] = useState({});
	
	
	const changeFieldValue = (e, fieldName, fieldValidator, onlyValidate) => {
		
		const fieldValue = onlyValidate ? e : e.target.value;
		const fieldError = !fieldValidator(fieldValue);
		
		if (onlyValidate) return fieldError;
		
		if (!fieldError) setFormErrors({...formErrors, [fieldName]: null});
		
		eval(`set${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}Value`)(fieldValue);
		
	};

	
	const resetContactForm = () => {
		setFormSubjectValue("");
		setFormNameValue("");
		setFormEmailValue("");
		setFormContentValue("");
	};
	
	useEffect(() => {
		return () => {
			resetContactForm();
			setFormErrors({});
		};
	}, []);
	
	
	const validateContactForm = () => {
		
		const errors = {};
		
		if (changeFieldValue(formSubjectValue,"formSubject",v => v.length > 5, true)) {
			errors.formSubject = "Prosimy podać temat wiadomości";
		}
		
		if (changeFieldValue(formNameValue,"formName",v => v.length > 5, true)) {
			errors.formName = "Prosimy podać imię i nazwisko";
		}
		
		if (changeFieldValue(formEmailValue,"formEmail", v => isEmail(v), true)) {
			errors.formEmail = "Prosimy podać temat wiadomości";
		}
		
		if (changeFieldValue(formContentValue,"formContent",v => v.length > 10, true)) {
			errors.formContent = "Prosimy podać treść wiadomości";
		}
		
		setFormErrors(errors);
		
		if (Object.entries(errors).length > 0) return null;
		
		if (sendMailRequestHandler) sendMailRequestHandler(
			formEmailValue, formNameValue, formSubjectValue, formContentValue
		);
		
	};

	
	return (
		<FormComponent lg={{span: 7, offset: 1}} cssClasses={"contact-form-component"}>
			
			<FormRowComponent cssClasses={"contact-form-row"}>
				<FormTextFieldComponent
					fieldValue={formSubjectValue}
					fieldValueHandler={e => changeFieldValue(e,"formSubject",v => v.length > 5)}
					fieldPlaceholder={`Temat`}
					fieldDisabledStatus={!!REST_API_REQUEST_STATUS}
					fieldError={formErrors.formSubject}
					cssClasses={"contact-form-input"}
				/>
			</FormRowComponent>
			
			<FormRowComponent cssClasses={"contact-form-row"}>
				<FormTextFieldComponent
					fieldValue={formNameValue}
					fieldValueHandler={e => changeFieldValue(e,"formName",v => v.length > 5)}
					fieldPlaceholder={`Imię i Nazwisko`}
					fieldDisabledStatus={!!REST_API_REQUEST_STATUS}
					fieldError={formErrors.formName}
					cssClasses={"contact-form-input"}
				/>
			</FormRowComponent>
			
			<FormRowComponent cssClasses={"contact-form-row"}>
				<FormEmailFieldComponent
					fieldValue={formEmailValue}
					fieldValueHandler={e => changeFieldValue(e,"formEmail",v => isEmail(v))}
					fieldPlaceholder={`E-mail`}
					fieldDisabledStatus={!!REST_API_REQUEST_STATUS}
					fieldError={formErrors.formEmail}
					cssClasses={"contact-form-input"}
				/>
			</FormRowComponent>
			
			<FormRowComponent cssClasses={"contact-form-row"}>
				<FormTextAreaFieldComponent
					fieldValue={formContentValue}
					fieldValueHandler={e => changeFieldValue(e,"formContent",v => v.length > 10)}
					fieldPlaceholder={"Treść Wiadomości"}
					fieldDisabledStatus={!!REST_API_REQUEST_STATUS}
					fieldError={formErrors.formContent}
					cssClasses={"contact-form-input"}
				/>
			</FormRowComponent>
			
			<FormRowComponent cssClasses={"contact-form-row submit-row"}>
				<FormSubmitButtonComponent
					buttonText={`Wyślij`}
					buttonClickHandler={() => validateContactForm()}
					buttonDisabledStatus={!!REST_API_REQUEST_STATUS}
				/>
			</FormRowComponent>
		
		</FormComponent>
	);
	
};


export default ContactPageFormComponent;
