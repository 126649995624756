import React, {useEffect, useRef, useState} from "react";

import GLOBAL_REST_API_DATA from "@Globals/API";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";

import PartnersPartnerComponent from "@UIPages/TextPages/Common/Partners/Partner";


const {URLS: {PARTNERS: {partnersList: PARTNERS_LIST_URL}}} = GLOBAL_REST_API_DATA;


export const PartnersPageComponent = ({}) => {
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const [PARTNERS_LIST, SET_PARTNERS_LIST] = useState(null);
	
	const GET_PARTNERS_LIST = async () => {
		try {
			const response = await fetch(PARTNERS_LIST_URL);
			const content = await response.json();
			SET_PARTNERS_LIST(content);
		} catch (e) {
			console.log(e);
			SET_PARTNERS_LIST({error: true});
		}
	};
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current);
		if (!PARTNERS_LIST_URL) return null;
		GET_PARTNERS_LIST();
	}, []);
	
	
	
	// useEffect(() => {
	// 	if (!REST_API_URL) return null;
	// 	fetch(`${REST_API_URL}/partners`)
	// 		.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
	// 		.then(resource => setPartnersList(resource))
	// 		.catch(error => {return {error: true, code: 500, message: error}})
	// 	;
	// 	return () => {
	// 		setPartnersList(null);
	// 	};
	// }, [REST_API_URL]);
	
	
	if (!PARTNERS_LIST) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={`Partnerzy`} />;
	
	
	const partnersListComponent = PARTNERS_LIST.map((partner, i) =>
		<PartnersPartnerComponent key={i} partnerData={partner} />
	);
	
	
	return (
		<MainSectionContainerComponent forwardRef={CONTAINER_REF} as={`main`} cssClasses={`partners-page-component`}>
			
			<MainSectionHeaderComponent headerText={"Partnerzy"} />
			
			<MainSectionContentComponent>
				{partnersListComponent}
			</MainSectionContentComponent>
		
		</MainSectionContainerComponent>
	);
	
};


export default PartnersPageComponent;
