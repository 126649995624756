import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";


export const ApplicationCopyrightsComponent = () => {
	
	
	return (
		<MainSectionContainerComponent cssClasses={`application-copyrights-component`}>
			
			<Col xs={12} lg={4} className={`copyrights-links`}>
				<NavLink to={`/polityka-prywatnosci`} title={`Polityka Prywatności`}>
					<span>{`Polityka Prywatności`}</span>
				</NavLink>
				<NavLink to={`/regulamin`} title={`Regulamin`}>
					<span>{`Regulamin`}</span>
				</NavLink>
			</Col>
			
			<Col xs={12} lg={4} className={`copyrights-information text-center`}>
				<span className={`copyrights-information-label`}>copyrights</span>
				<span className={`copyrights-information-value`}>{`©2021 PLKA`}</span>
			</Col>
			
			<Col xs={12} lg={{span: 3, offset: 1}} className={`copyrights-developers`}>
				<span className={`copyrights-developers-label`}>{`Developed by`}:</span>
				<a href={`https://webpros.pl`} title={`Developed by`} target={`_blank`} rel={`noreferrer noopener`}>webpros.pl</a>
			</Col>
			
		</MainSectionContainerComponent>
	);
	
};


export default ApplicationCopyrightsComponent;
