import React, {useState, useEffect, useRef} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";


import scrollToDomElement from "@Utilities/Scrolling";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";


import ContactPageContentComponent from "@UIPages/Common/ContactPage/Content";
import ContactPageFormComponent from "@UIPages/Common/ContactPage/Form";
import FormMessageComponent from "@Elements/Sections/Forms/Messages/Information";


const {URLS: {EMAILS: {sendEmailURL: SEND_EMAIL_URL}}} = GLOBAL_REST_API_DATA;


export const ContactPageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const CONTAINER_REF = useRef(null);
	useEffect(
		() => scrollToDomElement(CONTAINER_REF.current, window.innerWidth < 992 ? 150 : 100
		), []);
	
	
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(false);
	
	
	const sendMailRequest = async (senderEmail, senderName, messageTitle, messageContent) => {
		
		if (!SEND_EMAIL_URL) return {error: true, message: "Błąd wysyłania wiadomości (401)"};
		
		let RESPONSE;
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const FORM_DATA = new FormData();
		FORM_DATA.append("message_type", "contact_form");
		FORM_DATA.append("message_email", senderEmail);
		FORM_DATA.append("message_content", `Tytuł: ${messageTitle}\n\nEmail: ${senderEmail || ""}\nImię i nazwisko: ${senderName}\n\nTreść: ${messageContent || ""}`);
		
		try {
			const response = await fetch(SEND_EMAIL_URL, {
				method: "POST", body: FORM_DATA
			});
			const responseContent = await response.json();
			if (responseContent.success) {
				RESPONSE = {success: true};
				setSubmitSuccessStatus(true);
			} else {
				RESPONSE = {error: true, message: responseContent.message};
			}
		} catch (e) {
			RESPONSE = {error: true, message: e.message};
		}
		console.log(RESPONSE)
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		return RESPONSE;
	};
	
	useEffect(() => {
		return () => {
			setSubmitSuccessStatus(false);
		};
	}, [])
	
	
	return (
		<MainSectionContainerComponent forwardRef={CONTAINER_REF} as={`main`} cssClasses={`contact-page-component`}>
			
			<MainSectionContentComponent>
				
				<ContactPageContentComponent />
				
				{!submitSuccessStatus &&
				<ContactPageFormComponent
					sendMailRequestHandler={sendMailRequest}
				/>
				}
				
				{submitSuccessStatus &&
				<FormMessageComponent
					messageText={`Wiadomość została wysłana`}
					lg={{span: 7, offset: 1}}
					variant={`success`}
				/>
				}
			
			</MainSectionContentComponent>
		
		</MainSectionContainerComponent>
	);
	
};


export default ContactPageComponent;
