import React, {useEffect, useState} from "react";

import GLOBAL_REST_API_DATA from "@Globals/API";


import {Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import ArticlesListItemComponent from "@UIPages/TextPages/Articles/List/Item";


const {URLS: {PAGES: {newsPage: {articlesListPage: NEWS_PAGE_URL}}}} = GLOBAL_REST_API_DATA;


export const ArticlesListComponent = () => {
	
	
	const [ARTICLES_LIST, SET_ARTICLES_LIST] = useState(null);
	
	const GET_ARTICLES_LIST = async () => {
		try {
			const response = await fetch(NEWS_PAGE_URL);
			const content = await response.json();
			SET_ARTICLES_LIST(content?.results);
		} catch (e) {
			console.log(e);
			SET_ARTICLES_LIST({error: true});
		}
	};
	
	useEffect(() => {
		if (!NEWS_PAGE_URL) return null;
		GET_ARTICLES_LIST();
	}, [NEWS_PAGE_URL]);
	
	
	if (!ARTICLES_LIST) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	
	if (ARTICLES_LIST.error) return null;
	
	
	const ArticlesList = ARTICLES_LIST.map((article, i) =>
		<ArticlesListItemComponent key={i} articleData={article} />
	);
	
	
	return (
		<Col xs={12} className={"articles-list-component"}>
			{ArticlesList}
		</Col>
	);
	
	
};


export default ArticlesListComponent;
