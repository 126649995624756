import React, {useState, useEffect, useRef} from "react";

import GLOBAL_REST_API_DATA from "@Globals/API";


import {Col, Container, Row} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import JoinUsTypeSwitcherComponent from "@UIPages/Common/JoinUsPage/Switcher";
import AlreadyHasLeagueComponent from "@UIPages/Common/JoinUsPage/AlreadyLeague";
import CreateNewLeagueComponent from "@UIPages/Common/JoinUsPage/CreateLeague";


const {URLS: {PAGES: {joinUsPage: JOIN_US_PAGE_URL}}} = GLOBAL_REST_API_DATA;


export const JoinUsPageComponent = () => {
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const [JOIN_US_PAGE_CONTENT, SET_JOIN_US_PAGE_CONTENT] = useState(null);
	
	
	const GET_JOIN_US_PAGE_CONTENT = async () => {
		
		try {
			
			const response = await fetch(JOIN_US_PAGE_URL);
			const content = await response.json();
			
			const {join_us_page_title: joinUsPageTitle, join_us_page_content: joinUsPageContent} = content[0];
		
			SET_JOIN_US_PAGE_CONTENT({
				joinUsPageTitle,
				alreadyHasLeague: joinUsPageContent, createNewLeague: joinUsPageContent
			});
			
		} catch (e) {
			console.log(e);
		}
		
	};
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, window.innerWidth < 992 ? 250 : 300);
		if (!JOIN_US_PAGE_URL) return null;
		GET_JOIN_US_PAGE_CONTENT();
	}, []);
	
	
	const [joinUsSelectedOrderType, setJoinSelectedUsOrderType] = useState(1);
	
	
	return (
		<>
		<MainSectionContainerComponent forwardRef={CONTAINER_REF} cssClasses={"join-us-page-header-component"}>
		{JOIN_US_PAGE_CONTENT?.joinUsPageTitle ?
			<MainSectionHeaderComponent headerText={"Dołącz do Nas"} />
			:
			<SingleContentLoaderComponent />
		}
		</MainSectionContainerComponent>
		
		<Container fluid={true} className={"option-switcher-container"}>
			<Container>
				<Row>
					<JoinUsTypeSwitcherComponent
						joinUsSelectedOrderType={joinUsSelectedOrderType}
						joinUsSelectedOrderTypeHandler={setJoinSelectedUsOrderType}
					/>
				</Row>
			</Container>
		</Container>
		
		<MainSectionContainerComponent forwardRef={CONTAINER_REF} cssClasses={"join-us-page-component"}>
			
				<MainSectionContentComponent>
					
					<Col xs={12} className={"join-us-page-content"}>
						
						{joinUsSelectedOrderType === 1 &&
						<AlreadyHasLeagueComponent componentContent={JOIN_US_PAGE_CONTENT?.alreadyHasLeague}/>
						}
						
						{joinUsSelectedOrderType === 2 &&
						<CreateNewLeagueComponent componentContent={JOIN_US_PAGE_CONTENT?.createNewLeague}/>
						}
						
					</Col>
				
				</MainSectionContentComponent>
				
			</MainSectionContainerComponent>
			
		</>
	);
	
	
};


export default JoinUsPageComponent;
